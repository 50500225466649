import { ThemeInterface, themeNames } from "../theme";
import { colors } from "../../styles/variables";
import { FontNames, FontWeights, HexColor } from "@pepdirect/shared/types";

type PantyShopColorNames = "pepsiBlue" | "orange" | "brown" | "lightBlue";

export const PANTRYSHOP_COLORS: Record<PantyShopColorNames, HexColor> = {
  pepsiBlue: "#003069",
  orange: "#e07647",
  brown: "#8e6451",
  lightBlue: "#d1eeff",
};

const SHARED_HEADING_STYLES = {
  fontFamily: "font-secondary" as FontNames,
  fontWeight: "700" as FontWeights,
  color: PANTRYSHOP_COLORS.pepsiBlue,
};

export const PantryShopTheme: ThemeInterface = {
  name: themeNames.PantryShop,
  color: {
    primary: PANTRYSHOP_COLORS.pepsiBlue,
    secondary: PANTRYSHOP_COLORS.orange,
    tertiary: PANTRYSHOP_COLORS.brown,
    accent: PANTRYSHOP_COLORS.lightBlue,
    disabled: colors.gray,
    action: colors.green,
    error: colors.red,
    text: colors.darkGray,
  },
  button: {
    primary: {
      color: PANTRYSHOP_COLORS.pepsiBlue,
    },
    secondary: {
      color: colors.green,
    },
    tertiary: {
      color: PANTRYSHOP_COLORS.pepsiBlue,
      hollow: true,
    },
    cancel: {
      color: colors.mediumGray,
      hollow: true,
    },
    error: {
      color: colors.red,
      hollow: true,
    },
    text: {
      color: PANTRYSHOP_COLORS.pepsiBlue,
    },
    disabled: {
      hollow: true,
    },
    fontWeight: "500",
  },
  typography: {
    heading: {
      xxl: {
        fontSize: {
          desktop: "46px",
          mobile: "32px",
        },
        letterSpacing: "-1px",
        ...SHARED_HEADING_STYLES,
      },
      xl: {
        fontSize: {
          desktop: "36px",
          mobile: "24px",
        },
        letterSpacing: "-1px",
        ...SHARED_HEADING_STYLES,
      },
      l: {
        fontSize: {
          desktop: "24px",
          mobile: "20px",
        },
        letterSpacing: "-1px",
        ...SHARED_HEADING_STYLES,
      },
      m: {
        fontSize: {
          desktop: "18px",
          mobile: "18px",
        },
        ...SHARED_HEADING_STYLES,
      },
      s: {
        fontSize: {
          desktop: "16px",
          mobile: "16px",
        },
        ...SHARED_HEADING_STYLES,
      },
      xs: {
        fontSize: {
          desktop: "14px",
          mobile: "14px",
        },
        ...SHARED_HEADING_STYLES,
      },
      xxs: {
        fontSize: {
          desktop: "14px",
          mobile: "14px",
        },
        fontFamily: "font-primary",
        fontWeight: "400",
        color: colors.darkGray,
      },
    },
  },
};
