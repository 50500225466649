import React from "react";
import { CartItemUpdateDto } from "@pepdirect/shared/types";
import { CartFragment } from "services/graphql/generated";

export interface CartContextInterface {
  cart: CartFragment | null;
  loading: boolean;
  refreshCart(): void;
  updateCartItem(_dto: CartItemUpdateDto): Promise<void>;
  getSize(): number | undefined;
}

export const CartContext = React.createContext<CartContextInterface>({
  cart: null,
  loading: true,
  refreshCart: () => {},
  updateCartItem: async (_dto: CartItemUpdateDto) => {},
  getSize: () => {
    return 0;
  },
});
