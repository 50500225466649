import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

export const environment = publicRuntimeConfig.environment as string;
export const sentryEnvironment =
  publicRuntimeConfig.sentryEnvironment as string;
export const isProductionReady = ["staging", "production", "uat"].includes(
  environment
);
export const isLocalEnv = !isProductionReady && !process.env.CI;

export const decovoShopAppId = "b0414f96-89ee-4a2d-94ea-9b062d2a7212";
export const sentryDSN = publicRuntimeConfig.sentryDSN as string;

export const imgWidths = [345, 600, 690];

export const shipping = {
  us: {
    free: 0,
    below: "0",
    threshold: 1000000000000000000000000,
  },
};

export const cart = {
  discounts: {
    hideDiscountsMaxThreshold: 2,
    showSavingsMaxThreshold: 10,
  },
  maxCartQty: 15,
};

export const cookies = {
  localStorageUserKey: "caps_user",
  cartCookieName: "decovo-shoppingcart",
  secureCookie: isProductionReady,
  zipCode: "user-zip-code",
};

export const endpoints = {
  cdn: publicRuntimeConfig.cdn as string,
  productsImgCdn: publicRuntimeConfig.productsImgCdn as string,
  web: publicRuntimeConfig.web as string,
  api: publicRuntimeConfig.api as string,
  cart: publicRuntimeConfig.cart as string,
  checkout: publicRuntimeConfig.checkout as string,
  portalService: publicRuntimeConfig.portalService as string,
  portalFrontend: publicRuntimeConfig.portalFrontend as string,
  corporateFrontend: publicRuntimeConfig.corporateFrontend as string,
  internalApi: publicRuntimeConfig.internalApi as string,
};

export const headerPromotion = {
  shouldShow: true,
  isAlwaysVisible: true,
  promotionText: "Free Shipping Over $20",
};

export const links = {
  contact: "https://contact.pepsico.com/pantryshop",
};
