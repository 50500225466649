import React from "react";

export interface ZipCodeContextInterface {
  zipCode: string | null;
  userSetZipCode(zip: string): void;
  blockedItems: string[];
}

export const ZipCodeContext = React.createContext<ZipCodeContextInterface>({
  zipCode: null,
  userSetZipCode: (_zip: string) => {},
  blockedItems: [],
});
