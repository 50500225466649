import { FeatureFlagsQuery } from "services/graphql/generated";

export const isFeatureFlagEnabled = (
  featureFlags: FeatureFlagsQuery["featureFlags"],
  featureName: string
): boolean => {
  const isEnabled = featureFlags.some(
    (featureFlag) => featureFlag.name === featureName && featureFlag.enabled
  );

  return !!isEnabled;
};
