import Cookies, { CookieSetOptions } from "universal-cookie";
import { cookies, endpoints } from "config";
import URI from "urijs";

const expiryInSeconds = 7 * 24 * 60 * 60;
const cookie = new Cookies();

const cookieOptions: CookieSetOptions = {
  path: "/",
  domain: URI(endpoints.web || window.location.host).domain(),
  secure: cookies.secureCookie,
  maxAge: expiryInSeconds,
};

export const getZipCodeFromCookie = (): string | undefined => {
  return cookie.get(cookies.zipCode) || undefined;
};

export const setZipCodeCookie = (zip: string): void => {
  cookie.remove(cookies.zipCode);
  cookie.set(cookies.zipCode, zip, cookieOptions);
};
