import URI from "urijs";
import Cookies from "universal-cookie";
import { CartFragment } from "services/graphql/generated";
import { Cart } from "@pepdirect/shared/types";
import { endpoints, cookies, decovoShopAppId } from "config";

const appId = decovoShopAppId;
const cookie = new Cookies();
const cookieOptions = {
  path: "/",
  secure: cookies.secureCookie,
  domain: URI(endpoints.web).domain(),
  maxAge: 365 * 24 * 60 * 60,
};

export const getCartIdFromCookie = () => cookie.get(cookies.cartCookieName);

export const saveCartCookie = (id: string) => {
  // Attempt to remove the cookie set w/o a proper domain first,
  // otherwise the set will fail.
  cookie.remove(cookies.cartCookieName);
  cookie.set(cookies.cartCookieName, id, cookieOptions);
};

export const deleteCartCookie = () => {
  cookie.remove(cookies.cartCookieName, cookieOptions);
  // Attempt to remove cookies that may have been set on the wrong domain
  cookie.remove(cookies.cartCookieName);
};

export const getCheckoutUrl = (): string =>
  `${endpoints.checkout}/v3/${getCartIdFromCookie()}/${appId}`;

export const getSubtotal = (cart: CartFragment | null): number => {
  if (!cart?.items?.length) return 0;

  return (
    cart.items.reduce((subtotal, item) => {
      if (item?.product?.price) {
        return subtotal + item.product.price * item.quantity;
      } else {
        return subtotal;
      }
    }, 0) / 100
  );
};

// returns true if at least 1 item has a subscription
export const cartHasSubscriptionItem = (cart: Cart): boolean => {
  return cart?.items?.some((item) => item.subscriptionIntervalInDays);
};
