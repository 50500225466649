import React from "react";
import { useContext, useState } from "react";
import { ModalContext } from "context/modal";
import { useRouterWithRedirect } from "hooks/useRouterWithRedirect";
import { ForgotPasswordForm as PepForgotPasswordForm } from "@pepdirect/v3/ui/ForgotPasswordForm";
import { ResetPasswordDTO } from "@pepdirect/v3/types";
import { useRequestPasswordResetForUserMutation } from "services/graphql/generated";

export function ForgotPasswordForm({
  inModal = false,
}: {
  inModal?: boolean;
}): JSX.Element {
  const [requestPasswordMutation, { error, loading }] =
    useRequestPasswordResetForUserMutation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { open, close } = useContext(ModalContext);
  const { pushPreserveRedirect } = useRouterWithRedirect();

  const onSubmit = async (formValue: ResetPasswordDTO) => {
    try {
      await requestPasswordMutation({ variables: { email: formValue.email } });
      setIsSubmitted(true);
    } catch (e) {
      return e;
    }
  };

  const toLogin = () => {
    if (inModal) {
      open("LOGIN_MODAL");
    } else {
      close(null);
      pushPreserveRedirect("/sign-in");
    }
  };

  return (
    <PepForgotPasswordForm
      contactLinkUrl="https://contact.pepsico.com/pantryshop"
      isLoading={loading}
      isSubmitted={isSubmitted}
      error={error ? "An error occured. Please try again later" : ""}
      onSubmit={onSubmit}
      toLogin={toLogin}
    />
  );
}
