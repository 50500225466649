import React, { useContext } from "react";
import Image from "next/image";
import { Form } from "react-final-form";
import { ModalContext } from "context/modal";
import { ZipCodeContext } from "context/zipCode";
import { Button } from "@pepdirect/ui/button";
import { FieldInput } from "@pepdirect/ui/FieldInput";
import { Heading } from "@pepdirect/ui/heading";
import { validateWithYup, zipCodeSchema } from "@pepdirect/ui/validation";
import { colors } from "@pepdirect/ui/variables";
import st from "./zipCodeModal.module.scss";
import { UserContext } from "context/user";

interface ZipCodeForm {
  zipCode: string;
}

export function ZipCodeModal(): JSX.Element {
  const { currentUserId } = useContext(UserContext);
  const { zipCode, userSetZipCode } = useContext(ZipCodeContext);
  const { close } = useContext(ModalContext);
  const text = {
    hasZip: {
      header: "Delivering to ",
      body: "If you would like to update your shipping location zip code, please enter it below.",
      cta: "Update Location",
    },
    noZip: {
      header: "Enter your delivery location zip code.",
      body: "Your zip code helps us show what products are available in your area.",
      cta: "Shop Now",
    },
    common: {
      inputLabel: "Enter your zip code*",
      cancel: "Cancel",
    },
  };

  const onSubmit = ({ zipCode }: ZipCodeForm): void => {
    userSetZipCode(zipCode.substring(0, 5));
    close(currentUserId);
  };

  return (
    <div className={st.wrapper}>
      <div className={st.imageWrapper}>
        <Image
          src="/assets/delivery-modal-image.svg"
          alt="Box with parachute"
          width="162"
          height="162"
        />
      </div>
      <div className={st.title}>
        <Heading level="h2" size="l" color={colors.darkGray}>
          {zipCode && (
            <span>
              {text.hasZip.header} <span className={st.zipCode}>{zipCode}</span>
            </span>
          )}
          {!zipCode && text.noZip.header}
        </Heading>
      </div>
      <p className={st.bodyText}>
        {zipCode ? text.hasZip.body : text.noZip.body}
      </p>
      <Form
        onSubmit={onSubmit}
        initialValues={{ zipCode }}
        validate={validateWithYup(zipCodeSchema)}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FieldInput
              label={text.common.inputLabel}
              name="zipCode"
              autoComplete="postal-code"
            />
            <Button htmlType="submit" type="secondary" fullWidth>
              {zipCode ? text.hasZip.cta : text.noZip.cta}
            </Button>
            <Button type="tertiary" onClick={() => close(null)} fullWidth>
              {text.common.cancel}
            </Button>
          </form>
        )}
      />
    </div>
  );
}
